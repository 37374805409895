import { isDevelopment } from '../src/isDevelopment'

interface IXplanMicroFrontEndWindowHandover {
    bffNonceToken?: string;
    bffURL?: string;
}

declare global {
    interface Window {
        deployConfig: IXplanMicroFrontEndWindowHandover | undefined;
    }
}

const getNonceFromXplanConfig = (): string | undefined => {
    const { deployConfig } = window;
    
    return deployConfig ? deployConfig.bffNonceToken : undefined;
};

const getNonceInDevelopment = (): string => {
    const localStorageOverride = localStorage.getItem('bffNonceToken');
    return localStorageOverride || 'test.nonce';
};

const getApiUrlFromXplanConfig = (): string | undefined => {
    const { deployConfig } = window;
   
    return deployConfig ? deployConfig.bffURL : undefined;
};

const getApiUrlInDevelopment = (): string => {
    const localStorageOverride = localStorage.getItem('bffURL');
    return localStorageOverride || 'http://localhost:8091';
};

export const getNonce = (): string | undefined => {
    const isDev = isDevelopment();
    return isDev ? getNonceInDevelopment() : getNonceFromXplanConfig();
};

export const getApiUrl = (): string | undefined => {
    const isDev = isDevelopment();
    return isDev? getApiUrlInDevelopment() : getApiUrlFromXplanConfig();
};

export const setNonce = (nonce: string | undefined): void => {
    window.deployConfig = {
        ...window.deployConfig,
        bffNonceToken: nonce,
    };
};

export const setApiUrl = (url: string | undefined): void => {
    window.deployConfig = {
        ...window.deployConfig,
        bffURL: url,
    };
};
