import React from "react";
import "./App.css";
import { PortfolioComponent } from "./PortfolioComponent";

// declare global {
//   interface Window {
//     deployConfig: IXplanMicroFrontEndWindowHandover | undefined;
//   }
//   let isDevelopment : boolean
// }

const App: React.FC = () => {
  document.title = "FE Analytics";

  return (
    <html className="iress-theme-light">
      <head>
        <link href="node_modules/@iress/themes/iress-light/theme.css" />
        <link href="node_modules/@iress/themes/global.css" />
      </head>
      <body>
        <div>
          <h2 className="App-header">FE Analytics</h2>
          {<PortfolioComponent />}
        </div>
      </body>
    </html>
  );
};

export default App;
