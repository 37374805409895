import React, { FormEvent } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "@iress/themes/iress-light/theme.css";
import "@iress/themes/global.css";
import {
  Button,
  ButtonMode,
  Input,
  Checkbox,
  FormGroup,
  Fieldset,
  Notification
} from "@iress/oui";
import { getApiUrl } from "./xplanHandOver";
import { retrieveAuthenticationResult } from "./authenticationStore";
import { isDevelopment } from "./isDevelopment";
import Cookies from "js-cookie";
import { BffHelper } from "./BffHelper";
export interface PortfolioAccount {
  portfolioAccountId: string;
  portfolioAccountName: string;
  IsSelected: boolean; // Xplan does not need to supply this. If it does it should set it to FALSE.
}

export interface Portfolio {
  ownerId: number;
  ownerName: string;
  portfolioAccounts: PortfolioAccount[];
}

export interface PortfolioResponse {
  portfolios: Portfolio[];
  feLoginName: string; // Xplan does not need to supply this. If it does it should set it to an empty string.
}

export interface PortfolioComponentState {
  error: any;
  isLoaded: boolean;
  result: string | null;
  awsALBCookie: string | undefined;
  xplanPortfolioData: PortfolioResponse | null;
  processingMessage: JSX.Element | null;
  displayProgressIndicator: boolean;
}

export class PortfolioComponent extends React.Component<
  {},
  PortfolioComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: null,
      awsALBCookie: Cookies.get("AWSALB"),
      xplanPortfolioData: null,
      processingMessage: <div>Loading Portfolio data.</div>,
      displayProgressIndicator: true
    };
  }

  bffHelper: BffHelper | null = null;

  // Removes any portfolios that are not selected.
  // The controller is only interested in selected values.
  private removeUnselectedPortfolios(
    portfolioResponse: PortfolioResponse | null
  ): PortfolioResponse | null {
    if (portfolioResponse === null) {
      return null;
    }

    const returnValue = portfolioResponse;

    returnValue.portfolios.forEach(portfolioOwner => {
      portfolioOwner.portfolioAccounts = portfolioOwner.portfolioAccounts.filter(
        function (item) {
          // only return selected portfolios
          return item.IsSelected === true;
        }
      );
    });

    return returnValue;
  }
  async componentDidMount() {
    // Get the Client ID from the URL.
    const clientId: string | null = new URLSearchParams(
      new URL(document.URL).search
    ).get("entityid");
    if (clientId == null) {
      // I have left this as an alert as it should never happen in production.
      alert(
        'You must launch the SPA with a client Id. This should be in the form "?entityid=1234".'
      );
      return;
    }

    console.log(`isDevelopment ${isDevelopment()}`);
    if (isDevelopment()) {
      // Development mode using test data
      // insert a delay so that we can see the "Loading screen"
      await new Promise(resolve => setTimeout(resolve, 750));

      this.GenerateTestData(parseInt(clientId));
    } else {
      let gotJwtToken: boolean = false;

      this.bffHelper = new BffHelper(
        getApiUrl() || '', this.state.awsALBCookie || '');

      try {
        // wait for the JWT BEFORE trying to get the portfolio data.
        await this.GetJwtToken();
        gotJwtToken = true;
      }
      catch (error) {
        console.error(`Caught exception when trying to get JWT token.`);
        console.error(error);
        gotJwtToken = false;
      }
      if (gotJwtToken && retrieveAuthenticationResult()) {
        this.GetPortfolioData(parseInt(clientId));
      }
    }
  }

  public async GetJwtToken(): Promise<void> {

    return this.bffHelper?.GetJwtToken().then((result: string) => {
      this.setState({
        result: result,
        error: undefined
      });

      console.log(`Retrieved new JWT from store ${result}`);
    }).catch((error: Error) => {
      console.error(`Error Retrieving JWT token: "${error.message}"`);
      this.setState({
        error: error.message,
        displayProgressIndicator: false
      });
    });

  }

  public GetPortfolioData(clientId: number) {
    console.log("GetPortfolioData");
    console.log(this.state);

    this.bffHelper?.GetPortfolioData(clientId)
      .then((responseData) => {
        console.log(
          `Received portfolio data: ${JSON.stringify(responseData)}.`
        );
        this.setState({
          isLoaded: true,
          processingMessage: null,
          displayProgressIndicator: false,
          xplanPortfolioData: responseData,
          error: undefined
        });

      })
      .catch((error: Error) => {
        this.setState({
          isLoaded: true,
          processingMessage: null,
          displayProgressIndicator: false,
          error: error.message
        });
      });

  }


  private readonly sendPortfoliosHandler = () => {
    const validationMessage = this.validateInput();
    if (validationMessage === null) {
      const apiUrl = getApiUrl();
      console.log(apiUrl);
      const token = retrieveAuthenticationResult();
      console.log(token);
      if (!token || !apiUrl)
        Notification.error("Authentication Error");
      else {
        this.SendPortfolioData(apiUrl, token);
      }
    }
    else {
      Notification.error(validationMessage);
    }
  };

  // Send Portfolio data to Financial Express
  // TODO - move this to BffHelper.ts
  public SendPortfolioData(apiUrl: string, tokenValue: string) {
    const { xplanPortfolioData } = this.state;
    this.setState({
      processingMessage: (
        <div>
          Your portfolio data is being sent to FE Analytics, please wait...
        </div>
      ),
      displayProgressIndicator: true
    });

    fetch(`${apiUrl}/api/Portfolio/`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(this.removeUnselectedPortfolios(xplanPortfolioData)),
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenValue,
        "AWSALB": this.state.awsALBCookie || '',
        "AWSALBCORS": this.state.awsALBCookie || ''
      },
      credentials: "include"
    })
      .then(response => {
        if (response.status === 200) {
          const message = (
            <div className="ExportButton">
              <div className="App-progress-message">
                <p>Portfolios successfully exported.</p>
                <hr />
              </div>
              <FormGroup inline>
                <Button
                  mode={ButtonMode.Primary}
                  onClick={this.openFinancialExpress}
                  label="Launch FE Analytics"
                />
              </FormGroup>
            </div>
          );

          this.setState({
            processingMessage: message,
            displayProgressIndicator: false
          });
        } else {
          response.json().then(errorDetails => {
            this.setState({ processingMessage: null });
            Notification.error(errorDetails.userMessage);
          });
        }
        return response;
      })
      .catch(error => {
        // log and display the error message.
        console.log("Something bad happened " + error.toString());
        this.setState({
          processingMessage: <div className="App-progress-message Error">{error.toString()}</div>,
          isLoaded: true,
          displayProgressIndicator: false
        });
      })
      .finally(() => {
        // Set isProcessingApplication: false ?
      });
  };

  // Generates a list of Portfolios to display.
  private generatePortfolios(
    portfolios: PortfolioAccount[],
    personIndex: number
  ): any {
    let index = 0;

    return portfolios.map(portfolio => {
      return (
        <div key={portfolio.portfolioAccountId} className="portfolioLine">
          <Checkbox
            className="PortfolioCheckBox"
            checked={portfolio.IsSelected}
            label={portfolio.portfolioAccountName}
            labelHidden={true}
            onChange={event => this.onChecked(event)}
            id={`portfolio${personIndex},${index}`}
            name={`portfolio${personIndex},${index++}`}
          />
          <label key={portfolio.portfolioAccountName} htmlFor={`portfolio${personIndex},${index}`}>
            {portfolio.portfolioAccountName}
          </label>
        </div>
      );
    });
  }


  // Load portfolio data



  // Gets portfolio data from Xplan.

  // Generates Test Data - used to simulate the controller in the BFF.
  public GenerateTestData(clientId: number): PortfolioResponse {
    const responseData: PortfolioResponse = {
      feLoginName: "",
      portfolios: [
        {
          ownerId: clientId,
          ownerName: "John Doe",
          portfolioAccounts: [
            {
              portfolioAccountId: "1",
              portfolioAccountName: "Demo Portfolio - Kevin Express long naaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaame",
              IsSelected: false
            },
            {
              portfolioAccountId: "2",
              portfolioAccountName: "Unlisted funds - Kevin Express mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm",
              IsSelected: false
            },
            {
              portfolioAccountId: "3",
              portfolioAccountName: "John Doe AXA GIA",
              IsSelected: false
            },
            {
              portfolioAccountId: "8",
              portfolioAccountName: "John Doe SWids Pension",
              IsSelected: false
            },
            {
              portfolioAccountId: "9",
              portfolioAccountName: "John Doe AXA ISA",
              IsSelected: false
            },
            {
              portfolioAccountId: "10",
              portfolioAccountName: "Basic Portfolio - Fiona Express2 nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn",
              IsSelected: false
            }
          ]
        },
        {
          ownerId: clientId + 1,
          ownerName: "Jane Doe",
          portfolioAccounts: [
            {
              portfolioAccountId: "4",
              portfolioAccountName: "Jane Doe AXsdf sdf df sdfsdfsdf sdfsd f sdfsdfA ISA",
              IsSelected: false
            },
            {
              portfolioAccountId: "54",
              portfolioAccountName: "Mixed Investments - K&F Express mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm",
              IsSelected: false
            }
          ]
        },
        {
          ownerId: 0,
          ownerName: "Joint",
          portfolioAccounts: [
            {
              portfolioAccountId: "5",
              portfolioAccountName: "Custom Instrument Test nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn",
              IsSelected: false
            }
          ]
        }
      ]
    };

    this.setState({
      isLoaded: true,
      processingMessage: null,
      displayProgressIndicator: false,
      xplanPortfolioData: responseData
    });

    return responseData;
  }

  render() {
    const {
      error,
      isLoaded,
      xplanPortfolioData,
      processingMessage,
      displayProgressIndicator
    } = this.state;

    if (error) {
      return <div className="App-progress-message Error">
        <p>Error: {error.message}</p>
      </div>;
    } else if (!isLoaded) {
      return <div className="App-progress-message">
        <p>Loading Portfolios...</p>
        <CircularProgress />
      </div>;
    } else {
      if (processingMessage != null) {
        const progressInstance = (
          <p className="App-progress-message">{processingMessage}</p>
        );

        return (
          <div className="App-progress-message">
            {progressInstance}
            {displayProgressIndicator === true ? <CircularProgress /> : ""}
          </div>
        );
      }

      if (xplanPortfolioData == null) {
        return (
          <div className="App-progress-message">
            No portfolio data was returned.
          </div>
        );
      }

      const portfolioResponse = xplanPortfolioData;
      console.log(
        `Gets saved data that was originally obtained from BFF ${JSON.stringify(
          portfolioResponse
        )}.`
      );

      let personIndex = 0;

      const portfolioOwners = portfolioResponse.portfolios.map(
        portfolioOwner => {
          return (
            <p key={`Owner${personIndex}`}>
              <div key={portfolioOwner.ownerName} className="PortfolioOwner-panel">
                <Checkbox
                  className="PortfolioCheckBox PortfolioOwner-name"
                  checked={this.isAllSelected(portfolioOwner)}
                  onChange={event => this.onSelectAll(event)}
                  id={`Owner${personIndex}`}
                  name={`Owner${personIndex}`}
                  label={portfolioOwner.ownerName}
                />
                {this.generatePortfolios(
                  portfolioOwner.portfolioAccounts,
                  personIndex++
                )}
              </div>
              <hr />
            </p>
          );
        });

      return (
        <Fieldset>
          {/* Sign in panel */}
          <div className="Section-header">
            <FormGroup inline>
              <div className="PortfolioOwner-name">FE Sign In</div>
              <Input
                className="fe-account"
                value={xplanPortfolioData.feLoginName}
                defaultValue="Usually an email address..."
                onChange={(event: FormEvent<HTMLInputElement>) =>
                  this.onTextChanged(event)
                }
                id="feAccount"
                name="fe-account"
                placeholder="Please enter your FE Analytics account (usually an email address)"
              />
            </FormGroup>
          </div>
          {/* Portfolios panel */}
          <div className="Section-header">
            <div>{portfolioOwners}</div>
          </div>

          {/* Buttons panel */}
          <div className="ExportButton">
            <FormGroup inline>
              <Button
                mode={ButtonMode.Primary}
                onClick={this.sendPortfoliosHandler}
                label="Export to FE Analytics"
              />
            </FormGroup>
          </div>
        </Fieldset >
      );
    }
  }

  // event handler for the "Select ALL" checkbox
  private onSelectAll(event: any): void {
    const getOwnerIndex = function (id: string): number {
      console.log(`OwnerID = ${id.slice(5, 6)}`);
      return parseInt(id.slice(5, 6));
    };

    const setPortfolioValues = function (
      xplanPortfolioData: PortfolioResponse,
      ownerIndex: number,
      checked: boolean
    ): PortfolioResponse {
      const portfolios =
        xplanPortfolioData.portfolios[ownerIndex].portfolioAccounts;

      portfolios.forEach(element => {
        element.IsSelected = checked;
      });

      return xplanPortfolioData;
    };

    const { xplanPortfolioData } = this.state;

    if (xplanPortfolioData != null) {
      this.setState({
        xplanPortfolioData: setPortfolioValues(
          xplanPortfolioData,
          getOwnerIndex(event.target.name),
          event.target.checked
        )
      });
    }
  }

  // event handler for the checkboxes (except "Select ALL")
  private onChecked(event: any): void {
    const { xplanPortfolioData } = this.state;

    const getOwnerIndex = function (id: string): number {
      return parseInt(id.slice(9, 10));
    };
    const getPortfolioIndex = function (id: string): number {
      return parseInt(id.slice(11));
    };

    const id = event.target.name;

    if (xplanPortfolioData != null) {
      xplanPortfolioData.portfolios[getOwnerIndex(id)].portfolioAccounts[
        getPortfolioIndex(id)
      ].IsSelected = event.target.checked;

      this.setState({ xplanPortfolioData: xplanPortfolioData });
    }
  }

  private checkIfSelected(
    portfolio1: {
      portfolioAccountName: string;
      IsSelected: boolean;
    },
    value: boolean
  ): boolean {
    return portfolio1.IsSelected === value;
  }

  // Determines if the "Select ALL" checkbox should be selected.
  private isAllSelected(portfolioOwner: Portfolio): boolean {
    const result = portfolioOwner.portfolioAccounts.every(portfolio =>
      this.checkIfSelected(portfolio, true)
    );

    console.log(
      `IsAllSelected result ${portfolioOwner.ownerName} ${portfolioOwner.portfolioAccounts.length} ${result}`
    );

    return result;
  }

  // Event handler for when the user enters a new FE login name.
  private onTextChanged(myEvent: any): void {
    const { xplanPortfolioData } = this.state;

    const newText = myEvent.target.value;

    if (xplanPortfolioData != null) {
      xplanPortfolioData.feLoginName = newText;

      this.setState({ xplanPortfolioData: xplanPortfolioData });
    }
  }

  // Validates the user input.
  // If input is valid this will return null, else it will return a message describing the problem.
  private validateInput(): string | null {
    const { xplanPortfolioData } = this.state;

    if (xplanPortfolioData == null) {
      return "No data retrieved from Xplan.";
    }

    if (
      !xplanPortfolioData.feLoginName ||
      xplanPortfolioData.feLoginName.trim() === ""
    ) {
      return "You must enter a value for \"FE sign in\".\r\nThis will be an e-mail address.";
    }

    if (
      !xplanPortfolioData.portfolios.some(owner =>
        owner.portfolioAccounts.some(portfolio => portfolio.IsSelected)
      )
    ) {
      return "You must select at least one portfolio.";
    }

    return null;
  }

  private readonly openFinancialExpress = () => {
    // TODO - Colin Hill - 27/02/2020 - store the URL somewhere in config.
    // TODO - Colin Hill - 27/02/2020 - when this embedded in Xplan we need to decide what to do with the panel when we click on this link.
    window.open("https://analytics.financialexpress.net/login.aspx", "Financial Express", "noopener");
  };
}
