import { PortfolioResponse } from "./PortfolioComponent";
import { retrieveAuthenticationResult, storeAuthenticationResult } from "./authenticationStore";
import { getNonce } from "./xplanHandOver";

// Helper class to communicate with the BFF.
export class BffHelper {
    relativePath: string;
    awsALBCookie: string;

    constructor(relativePath: string, awsALBCookie: string) {
        this.relativePath = relativePath;
        this.awsALBCookie = awsALBCookie;

        console.log(`relativePath ${relativePath}`);
    }

    // Gets the JWT Token.
    public GetJwtToken(): Promise<string> {

        return new Promise((resolve, reject) => {
            const bffNonce = getNonce();
            if (!bffNonce) {
                reject(new Error('Serious Error, no nonce'));
            }
            fetch(`${this.relativePath}/api/authentication?token=${bffNonce}`, {
                headers: {
                    "AWSALB": this.awsALBCookie,
                    "AWSALBCORS": this.awsALBCookie
                },
                credentials: "include"
            })
                .then(res => res.text())
                .then(result => {
                    try {
                        // Usually the response is a simple string containing the JWT token.
                        //If the response is valid JSON then this indicates an error.
                        const data = JSON.parse(result);
                        console.log(`Error: "${data.message}"`);
                        reject(new Error(data?.message));
                    } catch {
                        const tokenValue = result;
                        console.log(`Retrieve new JWT from BFF '${tokenValue}'`);
                        storeAuthenticationResult(tokenValue);

                        resolve(tokenValue);
                    }
                },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    error => {
                        console.log(`Get JWT error handler: "${error}"`);
                        reject(error);
                    }
                );
        });
    }

    // Get Portfolio Data from Xplan using the BFF.
    public async GetPortfolioData(clientId: number): Promise<PortfolioResponse> {
        console.log("GetPortfolioData");

        // New JWT token.
        const tokenValue: string = retrieveAuthenticationResult() || '';
        console.log(`GetPortfolioData JWT token '${tokenValue}'`);

        if (tokenValue === '') {
            console.log(`GetPortfolioData JWT token is missing`);
            throw (new Error('JWT token is missing'));
        }

        try {

            return await fetch(`${this.relativePath}/api/Portfolio/${clientId}`, {
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: tokenValue,
                    "AWSALB": this.awsALBCookie,
                    "AWSALBCORS": this.awsALBCookie
                },
                credentials: "include"
            }).then((response) => response.text())
                .then((text) => {
                    console.log(`Got response =  ${text}.`);
                    try {
                        const data = JSON.parse(text);
                        return (data);
                    } catch {
                        console.error(`Server returned an invalid response that is not JSON.`);
                        throw (new Error('Server returned an invalid response that is not JSON.'));
                    }
                }).then(responseData => {
                    console.log(`Received portfolio data: ${JSON.stringify(responseData)}.`);

                    return responseData as PortfolioResponse;
                },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    error => {
                        console.error(`An error has occurred ${error}`);
                        throw (new Error(error));
                    });

        } catch (e) {
            console.log(`error ${e}`);
            throw (e);
        }
    }

};  